const GEN_SHARELINK_WINDOW_JS_URI = '/scripts/sharelink_window_4ai.js';
const GEN_PWA_INSALL_JS_URI = '/scripts/pwa_install_4ai.js';
const GEN_ONE_SUBMIT_JS_URI = '/scripts/one_submit_4ai.js';
const GEN_CTA_CLICK_PHONE_JS_URI = '/scripts/cta_click_phone.js';

const themePath = main_scripts.dist_path;

window.collections = {};

/*const SCRIPTS = [
    {
        'url' : GEN_SHARELINK_WINDOW_JS_URI,
        'sel' : '.share-links a',
        'col' : 'sharelinks'
    },
    {
        'url' : GEN_PWA_INSALL_JS_URI,
        'sel' : '.pwa-install-btn',
        'col' : 'addBtn'
    }
];

let selectors = [];
for(let script of SCRIPTS ) {
    selectors.push(script.sel);
}

for(let script of SCRIPTS ) {
    if(Array.from) {
        collections[script.col] = Array.from(document.querySelectorAll(script.sel));
        if(collections[script.col].length > 0) {
            if(script.lazy) {
                let encours = false,
                    observer = new IntersectionObserver(function(entries) {
                    for (let j = 0, lg = entries.length; j < lg; j++) {
                        if (entries[j].intersectionRatio <= 0 || encours) return;
                        encours = true;
                        new loadExt([themePath+script.url]);
                        for (let k = 0, lgt = entries.length; k < lgt; k++) {
                            observer.unobserve(entries[k].target);
                        }
                    }
                }, obsOptions);

                for (let i = 0, l = collections[script.col].length; i < l; i++) {
                    observer.observe(collections[script.col][i]);
                }

            } else {
                new loadExt([themePath+script.url]);
            }
        }
    }
}*/

// FONCTIONS JS FB MARCHAND
(function() {

    // Sticky header
    let lastPos = 0,
        tick = false;
    scrollPos();
    window.addEventListener('scroll', () => { scrollPos() }, false);
    document.addEventListener('click', (e) => { loginLink(e) }, false);

    let ux_qty_product = document.querySelector('.ux_quantity_select');

    // Correspondance entre input quantité natif et faux input quantité hors formulaire (iso maquettes)
    if(ux_qty_product) {
        // liste des composants de formules
        document.addEventListener('click', (e) => { ux_quantity(e) });
    }

    function scrollPos() {
        lastPos = window.scrollY;
        if (!tick) {
            window.requestAnimationFrame(function() {
                stickyHeader(lastPos);
                tick = false;
            });
        }

        tick = true;
    }

    function stickyHeader(pos) {
        let stickyClass = 'scroll';
        document.body.classList.toggle(stickyClass, !!pos);
    }

    function loginLink(e) {
        let link = e.target.closest('.showlogin');

        if(!link) return;
        e.preventDefault();
        let loginInput = document.querySelector('#login-form');
        let popinAccount = document.querySelector('#popin-account');
        if(loginInput) {
            loginInput.checked = true;
            if(popinAccount) {
                popinAccount.checked = true;
            }
        }

    }
    function ux_quantity(e) {
        let btn = e.target.closest('.ux_quantity_btn');
        if(btn) {
            let product = btn.getAttribute('data-for'),
                input = document.querySelector('#'+product),
                val = input ? parseFloat(input.value) : -1;
            if( val > 0) {
                input.value = (btn.id == "ux_quantity_more_"+product) ? val + 1 : (val > 1) ? val - 1 : val;

                if(jQuery !== undefined) {
                    jQuery(input).trigger('change');
                } else {
                    input.dispatchEvent(new Event('change'));
                }
            }
        }
    }
})();
